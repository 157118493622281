//----------------------------------------------------------------------------//
//Please Checking "package.json" file, homepage link when checking or changing.//
//https://syscms.infollective.com/view/                                      //
//http://192.168.1.23:81/syscms2/view/      
//--------------------------------------------------------------------------//

//please checking navbar.js file for logo 
//import Logo from '../Assets/logo/sylogo_sh.png';
//import Logo from '../Assets/logo/ship.png';

export const baseNameDev="/syscms2/view"
export const baseNameProd="/view"
//develop env
// export const globalHost = "http://192.168.1.23:81"
// export const globalPathLink="/syscms/backend/api/"
// export const globalAttachmentLink="/syscms/"


export const globalHost = "https://syscms0.infollective.com"
export const globalPathLink="/application/backend/api/"
export const globalAttachmentLink="/application/"

//production env
// export const globalHost = "https://syscms.infollective.com"
// export const globalPathLink="/application/backend/api/"
// export const globalAttachmentLink="/application/"

export const customStyles = {
    control: base => ({
       ...base,
       minHeight: 30
    }),
    dropdownIndicator: base => ({
       ...base,
       padding: 4
    }),
    clearIndicator: base => ({
       ...base,
       padding: 4
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    valueContainer: base => ({
       ...base,
       padding: '0px 6px'
    }),
    input: base => ({
       ...base,
       margin: 0,
       padding: 0
    }),
 };

 export const customStylesReadonly = {
    control: base => ({
       ...base,
       background: "#e9ecef",
       minHeight: 30
    }),
    dropdownIndicator: base => ({
       ...base,
       padding: 4
    }),
    clearIndicator: base => ({
       ...base,
       padding: 4
    }),
  
    valueContainer: base => ({
       ...base,
       padding: '0px 6px'
    }),
    input: base => ({
       ...base,
       margin: 0,
       padding: 0
    })
    
  
  };
  